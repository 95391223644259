<template>
    <div>
        <div>
            <div class="top_bar" v-if="$country.is_india">
                <NuxtLink :to="localePath('/engagement-rings')">Get 35% off* on Engagement Rings | Shop Now</NuxtLink>
            </div>
            <div v-else class="top_bar top_bar_mod" @click="openBFModal">
                <p class="text-us m-0 ">Black Friday EARLY ACCESS Sale! Get Up To 55% OFF*  &nbsp;<span class="pipe"> | </span> <br>  &nbsp; LIMITED TIME OFFER</p>
            </div>
            <div v-if="showModal">
                <Teleport  to="#teleports">
                    <div class="modal fade " data-bs-backdrop="true" id="modalTall" tabindex="-1" aria-labelledby="modalTallLabel" aria-hidden="true" ref="modalRef">
                        <div class="modal-dialog modal-lg modal-width">
                            <div class="modal-content model_block p-0">    
                                <div class="modal-body p-0">
                                    <div class="modal_body">
                                        <ModalTermsAndConditionsBF @hideModal="hideModal"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Teleport>
            </div>
        </div>
    </div>
</template>

<script setup>
    const localePath = useLocalePath()
    const { $country } = useNuxtApp() 
    const showModal = ref(false);
    const modalRef = ref(null);

    
    // const openPopup = ref(false)

    // const hideModal = () => {
    //     openPopup.value = false
    // }

    const openBFModal =()=>{
        showModal.value = true;
        nextTick(() => {
            const modalElement = modalRef.value;
            if (modalElement) {
                const modalInstance = new bootstrap.Modal(modalElement);
                modalInstance.show();
                // modalInstance.on('hidden.bs.modal', function (e) {
                    
                //     console.log('model is hidden', )
                // })
            }
        });
    }

    const hideModal = () => {
        const modalElement = modalRef.value;
        if (modalElement) {
            const modalInstance = bootstrap.Modal.getInstance(modalElement);
            if (modalInstance) {
                modalInstance.hide();
            }
        }
        showModal.value = false;
    };
</script>

<style scoped>



.uppercase{
    text-decoration: underline;
}

.top_bar {
    background: #405955;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    letter-spacing: 1px;
}
.top_bar_mod{
    background: #1a263a;
    cursor: pointer;
}
.top_bar a, .top_bar .text-us {
    color: #fff;
    width: 100%;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-us{
    text-transform: uppercase
}

.black_friday_stripe{
    background: #5c041d;
    text-align: center;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    letter-spacing: 1px;
    cursor: pointer;
}
.top_bar_mod br{
    display: none;
}

@media(min-width:992px){
    .modal-width.modal-lg{
        max-width: 900px;
    }
}
@media(max-width: 1024px) {
    .top_bar a, .top_bar .text-us  {
        width: auto;
    }
}

@media(max-width: 767px){
    .top_bar{
        padding: 5px;
    }
    .top_bar_mod br{
        display: block;
    }
    .pipe{
        display: none;
    }
}
</style>
